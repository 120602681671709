export const mockWithOptions = jest.fn();
export const mockWithCurrency = jest.fn();
export const mockWithLocale = jest.fn();
export const mockRequestObj = jest.fn();
export const mockFormatNull = jest.fn();
export const mockFormat = jest.fn();
export const mockShortFormat = jest.fn();
export const mockFormatNoCurrency = jest.fn();
export const mockUnit = jest.fn();
export const mockEquals = jest.fn();

const mock = jest.fn().mockImplementation(() => {
  return {
    withOptions: mockWithOptions,
    withCurrency: mockWithCurrency,
    withLocale: mockWithLocale,
    requestObj: mockRequestObj,
    formatNull: mockFormatNull,
    format: mockFormat,
    shortFormat: mockShortFormat,
    formatNoCurrency: mockFormatNoCurrency,
    unit: mockUnit,
    equals: mockEquals,
  };
});

export default mock;
